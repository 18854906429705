import React from "react";

const TabPanel = ({ children, value, name, ...rest }) => {
	return (
		<>
			{value === name &&
				<div
					role="tabpanel"
					id={`simple-tabpanel-${name}`}
					aria-labelledby={`simple-tab-${name}`}
					{...rest}
				>
					<>{children}</>
				</div>}
		</>
	);
};

export default TabPanel;
