import React, { useState, useEffect , useContext } from "react";

import { MenuItem, Divider, TextField  } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import { Menu } from "@material-ui/core";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import mime from "mime";

const MessageOptionsMenu = ({ message, menuOpen, handleClose, anchorEl, ticketId }) => {
	const { setReplyingMessage } = useContext(ReplyMessageContext);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationUpdateOpen, setConfirmationUpdateOpen] = useState(false);
	const [sendMessageToOtherTicketModalIsOpen, setSendMessageToOtherTicketModalIsOpen] = useState(false);
	const [openTickets, setOpenTickets] = useState([]);
	const [selectedTicket, setSelectedTicket] = useState(null);
	const [modalLoading, setModalLoading] = useState(false);
	const history = useHistory();
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		setSearchTerm('');
	  }, []);

	const handleDeleteMessage = async () => {
		try {
			await api.delete(`/messages/${message.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleUpdateMessage = async () => {
		try {
			await api.get(`/messages/file/${message.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleReplyMessage = () => {
		setReplyingMessage(message);
		handleClose();
	};

	const handleOpenConfirmationModal = () => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenUpdateConfirmationModal = () => {
		setConfirmationUpdateOpen(true);
		handleClose();
	};

	const sendMessageToAnotherTicketOpenModal = async () => {
		setModalLoading(true);
		setSendMessageToOtherTicketModalIsOpen(true);
		handleClose();
		const { data } = await api.get("/tickets", {
			params: {
				// here we can Filter by status, date, etc.
				queueId: "verificar"
			}
		});
		setOpenTickets(data.tickets);
		setModalLoading(false);
	};

	const sendMessageToAnotherTicket = async () => {
		setSendMessageToOtherTicketModalIsOpen(false);
		setSearchTerm('');

		try {
			if (message.mediaUrl) {
				fetch(message.mediaUrl)
					.then((response) => response.blob())
					.then(async (blob) => {
						let mimeType;
						if (message.mediaType.toLowerCase() === 'audio'){
							mimeType = 'audio/mp4';
						} 
						else {
							mimeType = mime.getType(message.mediaUrl);
						}
						const extension = mime.getExtension(mimeType);
						const filename = message.body + (extension ? '.' + extension : '');
						const mediaAsFile = new File([blob], filename, { type: mimeType });

						const formData = new FormData();
						formData.append("fromMe", true);
						formData.append("ack", 7);
						formData.append("medias", mediaAsFile);
						formData.append("body", message.body);
						try {
							await api.post(`/messages/${selectedTicket.id}`, formData);
							history.push(`/tickets/${selectedTicket.uuid}`);
						} catch (err) {
							toastError(err);
						}

					});
			} else {
				const messageToSend = {
					read: 1,
					fromMe: true,
					body: message.body,
					ack: 7
				};

				await api.post(`/messages/${selectedTicket.id}`, messageToSend);
				history.push(`/tickets/${selectedTicket.uuid}`);
			}

		} catch (err) {
			toastError(err);
		}
	};

	const filteredTickets = openTickets
    .filter(ticket => {
      const name = ticket.contact.name || ticket.contact.number;
      return name.toLowerCase().includes(searchTerm.toLowerCase());
    })
    .sort((a, b) => {
      const nameA = (a.contact.name || a.contact.number).toLowerCase();
      const nameB = (b.contact.name || b.contact.number).toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

	

	return (
		<>
			<ConfirmationModal
				title={"Deseja Atualizar a midia?"}
				open={confirmationUpdateOpen}
				onClose={setConfirmationUpdateOpen}
				onConfirm={handleUpdateMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<ConfirmationModal
				title={i18n.t("messageOptionsMenu.confirmationModal.title")}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteMessage}
			>
				{i18n.t("messageOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<Dialog
				open={sendMessageToOtherTicketModalIsOpen}
				onClose={() => {
					setSendMessageToOtherTicketModalIsOpen(false);
					setSearchTerm('');
				  }}
				fullWidth
				maxWidth="sm"
				scroll="paper"
			>
				<DialogTitle>Enviar mensagem à outro Ticket</DialogTitle>
				<DialogContent dividers>
						<TextField
							label="Buscar Contato"
							variant="outlined"
							fullWidth
							margin="normal"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					{modalLoading ? <CircularProgress color="inherit" size={20} /> : (
						<List>
						{filteredTickets.length === 0 ? "Nenhum ticket encontrado" :
						  filteredTickets.map((ticket) => (
							<ListItem button key={ticket.id} selected={ticket.id === selectedTicket?.id} onClick={() => setSelectedTicket(ticket)}>
							  <ListItemAvatar>
								<Avatar src={ticket.contact.profilePicUrl} />
							  </ListItemAvatar>
							  <ListItemText primary={ticket.contact.name || ticket.contact.number} />
							</ListItem>
						  ))
						}
					  </List>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={() => { setSendMessageToOtherTicketModalIsOpen(false); setSelectedTicket(null); setSearchTerm(''); }}
						variant="outlined"
					>
						Cancelar
					</Button>
					<Button
						color="primary"
						onClick={sendMessageToAnotherTicket}
						variant="outlined"
						disabled={!!!selectedTicket}
					>
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
			<Menu
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{message.mediaUrl && (
					<MenuItem onClick={handleOpenUpdateConfirmationModal}>
						Atualizar Midia
					</MenuItem>
				)}
				{message.fromMe && (
					<MenuItem onClick={handleOpenConfirmationModal}>
						{i18n.t("messageOptionsMenu.delete")}
					</MenuItem>
				)}
				{message.fromMe && (
					<Divider />
				)}

				<MenuItem onClick={handleReplyMessage}>
					{i18n.t("messageOptionsMenu.reply")}
				</MenuItem>
				<Divider />

				<MenuItem onClick={sendMessageToAnotherTicketOpenModal}>
					{i18n.t("messageOptionsMenu.sendToAnotherTicket")}
				</MenuItem>

			</Menu>
		</>
	);
};

export default MessageOptionsMenu;
