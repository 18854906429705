import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { Button, IconButton, StepContent, TextField } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //height: 400,
    [theme.breakpoints.down("sm")]: {
      maxHeight: "20vh",
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  input: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export function QueueOptionStepper({ queueId, options, updateOptions }) {
  const classes = useStyles();
  const [activeOption, setActiveOption] = useState(-1);

  const handleOption = (index) => async () => {
    setActiveOption(index);
    const option = options[index];

    if (option !== undefined && option.id !== undefined) {
      try {
        const { data } = await api.request({
          url: "/queue-options",
          method: "GET",
          params: { queueId, parentId: option.id },
        });
        const optionList = data.map((option) => {
          return {
            ...option,
            children: [],
            edition: false,
          };
        });
        option.children = optionList;
        updateOptions();
      } catch (e) {
        toastError(e);
      }
    }
  };

  const handleSave = async (option) => {
    try {
      if (option.id) {
        await api.request({
          url: `/queue-options/${option.id}`,
          method: "PUT",
          data: option,
        });
      } else {
        const { data } = await api.request({
          url: `/queue-options`,
          method: "POST",
          data: option,
        });
        option.id = data.id;
      }
      option.edition = false;
      updateOptions();
    } catch (e) {
      toastError(e);
    }
  };

  const handleEdition = (index) => {
    options[index].edition = !options[index].edition;
    updateOptions();
  };

  const handleDeleteOption = async (index) => {
    const option = options[index];
    if (option !== undefined && option.id !== undefined) {
      try {
        await api.request({
          url: `/queue-options/${option.id}`,
          method: "DELETE",
        });
      } catch (e) {
        toastError(e);
      }
    }
    options.splice(index, 1);
    options.forEach(async (option, order) => {
      option.option = order + 1;
      await handleSave(option);
    });
    updateOptions();
  };

  const handleOptionChangeTitle = (event, index) => {
    options[index].title = event.target.value;
    updateOptions();
  };

  const handleOptionChangeMessage = (event, index) => {
    options[index].message = event.target.value;
    updateOptions();
  };

  const handleAddOption = (index) => {
    const optionNumber = options[index].children.length + 1;
    options[index].children.push({
      title: "",
      message: "",
      edition: false,
      option: optionNumber,
      queueId,
      parentId: options[index].id,
      children: [],
    });
    updateOptions();
  };

  return <Stepper
    style={{ marginBottom: 0, paddingBottom: 0 }}
    nonLinear
    activeStep={activeOption}
    orientation="vertical"
  >
    {options.map((option, index) => <RenderStep
      index={index}
      option={option}
      handleOption={handleOption}
      options={options}
      handleOptionChangeTitle={handleOptionChangeTitle}
      classes={classes}
      handleSave={handleSave}
      handleDeleteOption={handleDeleteOption}
      handleEdition={handleEdition}
      handleOptionChangeMessage={handleOptionChangeMessage}
      handleAddOption={handleAddOption}
      queueId={queueId}
      updateOptions={updateOptions}
    />)}
  </Stepper>
}

export function QueueOptions({ queueId }) {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (queueId) {
      (async () => {
        try {
          const { data } = await api.request({
            url: "/queue-options",
            method: "GET",
            params: { queueId, parentId: -1 },
          });
          const optionList = data.map((option) => {
            return {
              ...option,
              children: [],
              edition: false,
            };
          });
          if (isMounted) setOptions(optionList);
        } catch (e) {
          toastError(e);
        }
      })();
    }
    return () => { isMounted = false };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateOptions = () => {
    setOptions([...options]);
  };

  const addOption = () => {
    const newOption = {
      title: "",
      message: "",
      edition: false,
      option: options.length + 1,
      queueId,
      parentId: null,
      children: [],
    };
    setOptions([...options, newOption]);
  };

  return (
    <div className={classes.root}>
      <br />
      <Typography>
        Opções
        <Button
          color="primary"
          size="small"
          onClick={addOption}
          startIcon={<AddIcon />}
          style={{ marginLeft: 10 }}
          variant="outlined"
        >
          Adicionar
        </Button>
      </Typography>
      <RenderStepper options={options} queueId={queueId} updateOptions={updateOptions} />
    </div>
  );
}

const RenderStepper = ({
  options,
  queueId,
  updateOptions
}) => {
  return <>
    {options.length > 0 && <QueueOptionStepper
      queueId={queueId}
      updateOptions={updateOptions}
      options={options}
    />}
  </>
};

const RenderStep = ({
  index,
  option,
  handleOption,
  options,
  handleOptionChangeTitle,
  classes,
  handleSave,
  handleDeleteOption,
  handleEdition,
  handleOptionChangeMessage,
  handleAddOption,
  queueId,
  updateOptions
}) => {
  return (
    <Step key={index}>
      <StepLabel style={{ cursor: "pointer" }} onClick={handleOption(index)}>
        <RenderTitle
          index={index}
          options={options}
          handleOptionChangeTitle={handleOptionChangeTitle}
          classes={classes}
          handleSave={handleSave}
          handleDeleteOption={handleDeleteOption}
          handleEdition={handleEdition}
        />
      </StepLabel>
      <StepContent>
        <RenderMessage
          index={index}
          options={options}
          handleOptionChangeMessage={handleOptionChangeMessage}
          classes={classes}
          handleEdition={handleEdition}
        />

        {option.id !== undefined && (
          <>
            <Button
              color="primary"
              size="small"
              onClick={() => handleAddOption(index)}
              startIcon={<AddIcon />}
              variant="outlined"
              className={classes.addButton}
            >
              Adicionar
            </Button>
          </>
        )}
        <QueueOptionStepper
          queueId={queueId}
          options={option.children}
          updateOptions={updateOptions}
        />
      </StepContent>
    </Step>
  );
};

const RenderMessage = ({
  index,
  options,
  handleOptionChangeMessage,
  classes,
  handleEdition
}) => {
  const option = options[index];
  if (option.edition) {
    return (
      <TextField
        style={{ width: "100%" }}
        multiline
        value={option.message}
        onChange={(event) => handleOptionChangeMessage(event, index)}
        size="small"
        className={classes.input}
        placeholder="Digite o texto da opção"
      />
    );
  }
  return (
    <Typography onClick={() => handleEdition(index)}>
      {option.message}
    </Typography>
  );
};

const RenderTitle = ({
  index,
  options,
  handleOptionChangeTitle,
  classes,
  handleSave,
  handleDeleteOption,
  handleEdition,
}) => {
  const option = options[index];
  if (option.edition) {
    return (
      <>
        <TextField
          value={option.title}
          onChange={(event) => handleOptionChangeTitle(event, index)}
          size="small"
          className={classes.input}
          placeholder="Título da opção"
        />
        <IconButton
          color="primary"
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={() => handleSave(option)}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          variant="outlined"
          color="secondary"
          size="small"
          className={classes.button}
          onClick={() => handleDeleteOption(index)}
        >
          <DeleteOutlineIcon />
        </IconButton>
      </>
    );
  }
  return (
    <Typography>
      {option.title !== "" ? option.title : "Título não definido"}
      <IconButton
        variant="outlined"
        size="small"
        className={classes.button}
        onClick={() => handleEdition(index)}
      >
        <EditIcon />
      </IconButton>
    </Typography>
  );
};
