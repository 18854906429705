import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ChatIcon from '@material-ui/icons/Chat';

import TicketsManagerTabs from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";
import TicketAdvancedLayout from "../../components/TicketAdvancedLayout";

import { TicketsContext } from "../../context/Tickets/TicketsContext";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
    header: {
    },
    content: {
        overflow: "auto"
    },
    placeholderContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        backgroundColor: "#eee"
    },
    placeholderItem: {
    }
}));

const TicketAdvanced = (props) => {
    const classes = useStyles();
    const { ticketId } = useParams();
    const [option, setOption] = useState(0);
    const { currentTicket, setCurrentTicket } = useContext(TicketsContext)

    useEffect(() => {
        if (currentTicket.id !== null) {
            setCurrentTicket({ id: currentTicket.id, code: '#open' })
        }
        if (!ticketId) {
            setOption(1)
        }
        return () => {
            setCurrentTicket({ id: null, code: null })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (currentTicket.id !== null) {
            setOption(0)
        }
    }, [currentTicket])

    const handleOption = (opt) => {
        setOption(opt)
    }

    return (
        <TicketAdvancedLayout>
            <Box className={classes.header}>
                <BottomNavigation
                    value={option}
                    onChange={(event, newValue) => {
                        setOption(newValue);
                    }}
                    showLabels
                    className={classes.root}
                >
                    <BottomNavigationAction label="Ticket" icon={<ChatIcon />} />
                    <BottomNavigationAction label="Atendimentos" icon={<QuestionAnswerIcon />} />
                </BottomNavigation>
            </Box>
            <Box className={classes.content}>
                {option === 0 ? <RenderMessageContext ticketId={ticketId} placeholderContainerClass={classes.placeholderContainer} placeholderItemClass={classes.placeholderItem} handleOption={handleOption} /> : <RenderTicketsManagerTabs />}
            </Box>
        </TicketAdvancedLayout>
    );
};

// TODO: Check if these items are working
const RenderTicketsManagerTabs = () => {
    return <TicketsManagerTabs />
}

const RenderMessageContext = ({ ticketId, placeholderContainerClass, placeholderItemClass, handleOption }) => {
    if (ticketId) return <Ticket />;
    return <RenderPlaceholder placeholderContainerClass={placeholderContainerClass} placeholderItemClass={placeholderItemClass} handleOption={handleOption} />;
}

const RenderPlaceholder = ({ placeholderContainerClass, placeholderItemClass, handleOption }) => {
    return <Box className={placeholderContainerClass}>
        <div className={placeholderItemClass}>{i18n.t("chat.noTicketMessage")}</div><br />
        <Button onClick={() => handleOption(1)} variant="contained" color="primary">
            Selecionar Ticket
        </Button>
    </Box>
}

export default TicketAdvanced;
